import Confetti from 'react-confetti';
import { useWindowSize } from '@uidotdev/usehooks';
import {FuuzeButton, FuuzeColors} from "@fuuze/shared";
import { useEffect, useRef, useState } from 'react';
import { Card, Container, FlexColumn, H2Bold } from "@fuuze/shared";
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useStripeCheckoutSession } from '../../Hooks/queries/stripe.queries.hook.ts';
import { TailSpin } from 'react-loader-spinner';
import { useTimer } from 'react-timer-hook';
import { Trans, useTranslation } from 'react-i18next';
import { useUser } from '../../Hooks/queries/user.queries.hook.ts';
import { ChannelPreview } from '../Creator/ManageChannels/ChannelPreview.tsx';
import { DotLottieCommonPlayer, DotLottiePlayer } from '@dotlottie/react-player';
import { useQueryClient } from '@tanstack/react-query';
import { podcastQueryKeys } from '../../Hooks/queries/podcast.queries.hook.ts';

export function ConfirmPurchasePage() {
  const { width, height } = useWindowSize();
  const { i18n, t } = useTranslation('pages:confirmPurchase');
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const lottieRef = useRef<DotLottieCommonPlayer>(null);
  const timer = useTimer({
    expiryTimestamp: new Date(Date.now() + 3000),
    autoStart: false,
    onExpire: () => setConfettiRunning(false),
  });

  const [confettiRunning, setConfettiRunning] = useState(true);

  // Queries
  const user = useUser();
  const checkoutSession = useStripeCheckoutSession(params.get('session_id'));

  useEffect(() => {
    if (checkoutSession.isFetched && !checkoutSession.data) {
      navigate('/');
    } else {
      timer.start();
      queryClient.invalidateQueries({ queryKey: podcastQueryKeys.podcastEpisodes(checkoutSession.data?.channel.id) });
    }
  }, [checkoutSession]);

  if (!checkoutSession.data) {
    return (
      <Container style={{ alignItems: 'center', justifyContent: 'center' }}>
        <TailSpin height="80" width="80" color={FuuzeColors.orange.orange100} radius="1" visible={true} />
      </Container>
    );
  }

  return (
    <>
      <Confetti
        width={width || 0}
        height={height || 0}
        recycle={confettiRunning}
        colors={Object.values(FuuzeColors.orange)}
      />
      <Container>
        <FlexColumn style={{ alignItems: 'center', gap: 8 }}>
          <DotLottiePlayer style={{ width: 100 }} ref={lottieRef} src="/lottie/confirm.json" loop={1} autoplay />
          <h1>{t('title')}</h1>
        </FlexColumn>

        <Card level={200}>
          <H2Bold style={{ marginBottom: 16 }}>{t('paymentSummary')}</H2Bold>
          <FlexColumn style={{ gap: 8 }}>
            <p>
              <Trans
                t={t}
                i18nKey="paidAmount"
                values={{
                  amount: Intl.NumberFormat(user.data!.country || i18n.language, {
                    style: 'currency',
                    currency: checkoutSession.data.lineItem.currency,
                  }).format(checkoutSession.data.lineItem.amount_total / 100),
                }}
              />
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="amountForCreator"
                values={{
                  amount: Intl.NumberFormat(user.data!.country || i18n.language, {
                    style: 'currency',
                    currency: checkoutSession.data.lineItem.currency,
                  }).format(
                    (+checkoutSession.data.lineItem.price!.metadata['creatorAmount'] +
                      +checkoutSession.data.lineItem.price!.metadata['platformFee']) /
                      100,
                  ),
                }}
              />
            </p>
            <small style={{ color: FuuzeColors.dark.dark700, alignSelf: 'flex-end' }}>
              {t('amountForCreatorNote')}
            </small>
          </FlexColumn>
        </Card>

        <Card level={200}>
          <FlexColumn>
            <ChannelPreview channel={checkoutSession.data.channel} />
            <NavLink style={{ alignSelf: 'center' }} to={`/podcast/${checkoutSession.data.channel.slug}`}>
              <FuuzeButton outline>{t('cta')}</FuuzeButton>
            </NavLink>
          </FlexColumn>
        </Card>
      </Container>
    </>
  );
}
