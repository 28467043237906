import Stripe from 'stripe';
import {Tier} from './tier.model.ts';
import {PodcastDTO, UserDTO} from '@fuuze/shared';
import {Podcast} from "./podcast.model.ts";
import {immerable} from "immer";
import {NonPopulatedError} from "./errors.model.ts";

export class User implements UserDTO {
  [immerable] = true

  country: string;
  id: string;
  ownedChannels: Podcast[];
  pseudo?: string;
  stripeAccount?: { default_currency?: string | undefined; id: string; details_submitted: boolean; };
  stripeCustomer?: {
    subscriptions?: {
      data: {
        items: {
          data: {
            id: string;
            price: {
              product: any;
              id: string;
              currency: string;
              metadata: { [x: string]: string; };
              unit_amount: number | null;
            };
          }[];
        };
        id: string;
        currency: string;
        cancel_at_period_end: boolean;
        current_period_end: number;
        default_payment_method: unknown;
        latest_invoice: string | { id: string; next_payment_attempt: number | null; } | null;
        schedule: unknown;
        status: string;
      }[];
    } | undefined;
    currency?: string | null | undefined;
    id: string;
    payment_methods: {
      card?: { brand: string; exp_year: number; exp_month: number; last4: string; } | undefined;
      id: string;
      type: string;
    }[];
    invoice_settings: { default_payment_method: unknown; };
  };
  subscribedChannels: (string | Podcast)[];

  constructor(data: UserDTO) {
    if (data.ownedChannels.some(c => typeof c === 'string')) throw new NonPopulatedError('user.ownedChannels');
    this.id = data.id;
    this.country = data.country;
    this.ownedChannels = data.ownedChannels.map(c => new Podcast(c as PodcastDTO));
    this.pseudo = data.pseudo;
    this.stripeAccount = data.stripeAccount;
    this.stripeCustomer = data.stripeCustomer;
    this.subscribedChannels = data.subscribedChannels.map(c => typeof c === 'string' ? c : new Podcast(c as PodcastDTO));
  }

  get isCreator(): boolean {
    return this.ownedChannels.length > 0;
  }

  getSubscriptionForChannel(channelId: string) {
    return this.stripeCustomer?.subscriptions?.data.find(
      (s) => (s.items.data[0].price.product as Stripe.Product).metadata.channelId === channelId,
    );
  }

  getSubscriptionForTier(tier: Tier) {
    return this.stripeCustomer?.subscriptions?.data.find((s) => s.items.data[0].price.id === tier.stripePrice.id);
  }
}
