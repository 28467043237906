import styled from 'styled-components';
import ShowMoreText from 'react-show-more-text';
import {useTranslation} from 'react-i18next';
import DOMPurify from 'dompurify';

type ReadMoreProps = {
  text: string;
  isHtml?: boolean;
  lines?: number;
};

export function ReadMore({text, isHtml, lines = 2}: ReadMoreProps) {
  const {t} = useTranslation('components:readMore');

  return (
    <ShowMoreTextStyled
      lines={lines}
      more={<Trigger>{t('readMore')}</Trigger>}
      less={<Trigger>{t('showLess')}</Trigger>}>
      {isHtml ? <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text)}}/> : <p>text</p>}
    </ShowMoreTextStyled>
  );
}

const ShowMoreTextStyled = styled(ShowMoreText)`
  width: 100%;
  font-size: ${(props) => props.theme.typography.body.fontSize}px;

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.orange.orange70};
  }
`;

const Trigger = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.orange.orange100};
  align-self: flex-end;
`;
