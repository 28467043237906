import {ItemDTO} from "@fuuze/shared";
import {Channel} from "./channel.model.ts";
import {immerable} from "immer";

export class Item<C extends Channel = Channel> implements ItemDTO {
  [immerable] = true

  id: string;
  author?: string;
  categories: string[];
  channel: C;
  description?: string;
  enclosureUrl?: string;
  inCreation?: boolean;
  image: string;
  image100?: string;
  image30?: string;
  image600?: string;
  image60?: string;
  itemUser?: { listenedOrRead: boolean; position: number; };
  pubDate?: Date;
  slug: string;
  title: string;
  visibility: ItemVisibility[];

  constructor(item: ItemDTO) {
    this.id = item.id;
    this.author = item.author;
    this.categories = item.categories;
    this.channel = item.channel as C;
    this.description = item.description;
    this.enclosureUrl = item.enclosureUrl;
    this.image = item.image;
    this.image30 = item.image30;
    this.image60 = item.image60;
    this.image100 = item.image100;
    this.image600 = item.image600;
    this.inCreation = item.inCreation;
    this.itemUser = item.itemUser;
    this.pubDate = item.pubDate;
    this.slug = item.slug;
    this.title = item.title;
    this.visibility = item.visibility as Item['visibility'];
  }

  isVisibilityTiered() {
    return !this.visibility.includes('public') && !this.visibility.includes('hidden');
  }

  isVisibility(check: 'public' | 'hidden') {
    return this.visibility.includes(check);
  }
}

export type ItemVisibility = ('public' | 'hidden' | (string & {}));