import { clsx } from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';
import { MdCheckCircleOutline, MdErrorOutline } from 'react-icons/md';
import { RotatingLines } from 'react-loader-spinner';
import styled, { CSSProperties } from 'styled-components';
import { FuuzeColors } from "@fuuze/shared";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const iconStyle: CSSProperties = {
  marginLeft: -25,
  marginRight: 10,
};

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean;
  valid?: boolean;
  loading?: boolean;
  ref?: any;
};

function InputComponent({ error, valid, loading, ref, ...props }: InputProps) {
  return (
    <Wrapper>
      <input
        {...props}
        ref={ref}
        className={clsx({ error, valid, 'fuuze-input': true })}
        style={{ ...props.style, flex: 1, paddingRight: 35 }}
      />
      {!loading && error && <MdErrorOutline style={iconStyle} fill={FuuzeColors.red} />}
      {!loading && valid && <MdCheckCircleOutline style={iconStyle} fill={FuuzeColors.green} />}
      {loading && (
        <div style={iconStyle}>
          <RotatingLines width="16" strokeColor={FuuzeColors.orange.orange100} />
        </div>
      )}
    </Wrapper>
  );
}

export const FuuzeInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => InputComponent({ ...props, ref }));
