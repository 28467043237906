import {Item, ItemVisibility} from './item.model';
import {Channel} from "./channel.model.ts";
import {PodcastDTO} from "@fuuze/shared";
import {PodcastEpisodeDTO} from "@fuuze/shared";
import {immerable} from "immer";

export class Podcast extends Channel implements PodcastDTO {
  [immerable] = true

  author: string;
  config: {
    defaultItemsVisibility: ItemVisibility[];
    isPublished: boolean;
    usePodcastImageAsEpisodeImage: boolean;
  };
  description: string;
  explicit: boolean;
  feedUrl: string;
  image: string;
  image100?: string;
  image30?: string;
  image600?: string;
  image60?: string;
  items: (string | PodcastEpisode)[];
  itunesTrackId?: number;
  ownerEmail?: string;
  summary?: string;

  constructor(data: PodcastDTO) {
    super(data);
    this.author = data.author;
    this.config = data.config as Podcast['config'];
    this.explicit = data.explicit;
    this.feedUrl = data.feedUrl;
    this.items = data.items.map(item => typeof item === 'string' ? item : new PodcastEpisode(item as PodcastEpisodeDTO));
    this.ownerEmail = data.ownerEmail;
    this.summary = data.summary;
    this.description = data.description;
    this.itunesTrackId = data.itunesTrackId;
    this.image = data.image;
    this.image30 = data.image30;
    this.image60 = data.image60;
    this.image100 = data.image100;
    this.image600 = data.image600;
  }


  static transformRawDataToPodcast(data: PodcastDTO) {
    return new Podcast(data);
  }

  isHostedByFuuze() {
    return this.feedUrl.startsWith('https://fuuze.app');
  }

  getTier(id: string) {
    return this.tiers.find(tier => tier.id === id);
  }
}

export class PodcastEpisode extends Item<Podcast> implements PodcastEpisodeDTO {
  [immerable] = true

  duration?: number | undefined;
  explicit: boolean;

  constructor(episode: PodcastEpisodeDTO) {
    super(episode);
    this.duration = episode.duration;
    this.explicit = episode.explicit
  }

  static transformRawDataToPodcastEpisode(data: PodcastEpisodeDTO) {
    return new PodcastEpisode(data);
  }
}