import {mountStoreDevtool} from 'simple-zustand-devtools';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {subscribeWithSelector} from 'zustand/middleware';
import {enableMapSet} from 'immer';
import {usePlayerStore} from './player.store.ts';
import {Podcast} from "../models/podcast.model.ts";

enableMapSet();

type StoreState = {
  // Store the value of the last search navigation to be able to restore it when the user clicks on the search icon
  lastSearchNavigation?: string;
  search?: string;
  podcastSelected?: Podcast;
};

type StoreActions = {
  setLastSearchNavigation: (lastSearchNavigation: string) => void;
  setSearch: (search: string) => void;
  setPodcastSelected: (podcastChannel: Podcast) => void;
  reset: () => void;
};

type Store = StoreState & StoreActions;

const initialState: StoreState = {
  lastSearchNavigation: undefined,
  search: undefined,
  podcastSelected: undefined,
};

export const useStore = create<Store>()(
  subscribeWithSelector(
    immer((set, get) => ({
      ...initialState,
      setLastSearchNavigation: (lastSearchNavigation) => set({lastSearchNavigation}),
      setSearch: (search) => set({search}),
      setPodcastSelected: (podcastChannel) =>
        set((state) => {
          state.podcastSelected = podcastChannel;
        }),
      reset: () => set(() => ({...initialState})),
    })),
  ),
);

if (import.meta.env.DEV) {
  mountStoreDevtool('Store', useStore);
  mountStoreDevtool('PlayerStore', usePlayerStore);
}
