import {createBrowserRouter, Navigate, Outlet, ScrollRestoration} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import {IsUserCreatorRouteGuard} from './Components/Guards/IsUserCreatorRouteGuard.tsx';
import {ConfirmPurchasePage} from './Pages/ConfirmPurchase/ConfirmPurchasePage.tsx';
import {FlexColumn} from "@fuuze/shared";

// Guards
const UserLoggedGuard = lazy(() => import('./Components/Guards/UserLogged.guard'));

// Auth pages
const LoginPage = lazy(() => import('./Pages/Auth/LoginPage'));
const RegisterPage = lazy(() => import('./Pages/Auth/RegisterPage'));
const ForgotPasswordPage = lazy(() => import('./Pages/Auth/ForgotPasswordPage'));
const AdditionalInfoPage = lazy(() => import('./Pages/Auth/AdditionalInfoPage'));
const ConfirmEmailPage = lazy(() => import('./Pages/Auth/ConfirmEmailPage'));

// Utility page
const PageContainer = lazy(() => import('./Pages/PageContainer'));

// Podcast pages
const SearchPage = lazy(() => import('./Pages/Search/SearchPage'));
const PodcastChannelDetailPage = lazy(() => import('./Pages/PodcastChannelDetail/PodcastChannelDetailPage'));
const TiersPage = lazy(() => import('./Pages/Tiers/TiersPage'));
const PodcastEpisodeDetailPage = lazy(() => import('./Pages/PodcastEpisodeDetail/PodcastEpisodeDetailPage'));

const LibraryPage = lazy(() => import('./Pages/Library/Library.page'));
const BecomeCreatorPage = lazy(() => import('./Pages/BecomeCreator/BecomeCreator.page.tsx'));

// Creator pages
const ManageTiersPage = lazy(() => import('./Pages/Creator/Tiers/CreatorTiersPage.tsx'));
const EditTierPage = lazy(() => import('./Pages/Creator/EditTier/EditTierPage'));
const ManageChannelPage = lazy(() => import('./Pages/Creator/ManageChannels/ManageChannelPage'));

// Settings pages
const SettingsPage = lazy(() => import('./Pages/Settings/SettingsPage.tsx'));
const SettingsGeneralOutlet = lazy(() => import('./Pages/Settings/General/SettingsGeneralOutlet.tsx'));
const SettingsSubscriptionOutlet = lazy(() => import('./Pages/Settings/Subscriptions/SettingsSubscriptionsOutlet.tsx'));
const SettingsPaymentsOutlet = lazy(() => import('./Pages/Settings/Payments/SettingsPaymentsOutlet.tsx'));

const NotFoundPage = lazy(() => import('./Pages/404'));

const PageLoader = (Page: ReturnType<typeof lazy>) => {
  const Fallback = () => <FlexColumn style={{alignItems: 'center', justifyContent: 'center', height: '100dvh'}}/>;

  return (
    <Suspense fallback={<Fallback/>}>
      <ScrollRestoration/>
      <Page/>
    </Suspense>
  );
};

export const router = createBrowserRouter([
  {path: '/login', element: PageLoader(LoginPage)},
  {path: '/register', element: PageLoader(RegisterPage)},
  {path: '/forgotPassword', element: PageLoader(ForgotPasswordPage)},
  {path: '/additionalInfo', element: PageLoader(AdditionalInfoPage)},
  {path: '/confirmEmail', element: PageLoader(ConfirmEmailPage)},
  {
    path: '/',
    element: <Suspense><UserLoggedGuard>{<PageContainer/>}</UserLoggedGuard></Suspense>, //PageLoader(<UserLoggedGuard>{PageContainer}</UserLoggedGuard>),
    children: [
      {path: '/', element: <Navigate to="/search" replace={true}/>},
      {path: '/search', element: PageLoader(SearchPage)},
      {
        path: '/podcast',
        children: [
          {path: ':channelSlug', element: PageLoader(PodcastChannelDetailPage)},
          {path: ':channelSlug/tiers', element: PageLoader(TiersPage)},
          {path: ':channelSlug/episode/:itemSlug', element: PageLoader(PodcastEpisodeDetailPage)},
          {index: true, path: '*', element: <Navigate to="/" replace={true}/>},
        ],
      },
      {path: 'library', element: PageLoader(LibraryPage)},
      {path: 'becomeCreator', element: PageLoader(BecomeCreatorPage)},
      {
        path: 'confirmPurchase',
        element: <ConfirmPurchasePage/>,
      },
      {
        path: '/creator',
        element: (
          <IsUserCreatorRouteGuard>
            <Outlet/>
          </IsUserCreatorRouteGuard>
        ),
        children: [
          {
            path: 'tiers/:tierId',
            element: PageLoader(EditTierPage),
          },
          {
            path: 'tiers',
            element: PageLoader(ManageTiersPage),
          },
          {path: 'podcast', element: PageLoader(ManageChannelPage)},
        ],
      },
      {
        path: '/settings',
        element: PageLoader(SettingsPage),
        children: [
          {index: true, element: <Navigate to="general" replace={true}/>},
          {path: 'general', element: PageLoader(SettingsGeneralOutlet)},
          {path: 'subscriptions', element: PageLoader(SettingsSubscriptionOutlet)},
          {path: 'payments', element: PageLoader(SettingsPaymentsOutlet)},
        ],
      },
    ],
  },
  {path: '*', element: <NotFoundPage/>},
], {basename: '/app'});
