import {FormProvider, useForm} from "react-hook-form";
import * as yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {useStore} from "../../../store/store.ts";
import {FlexColumn, FuuzeButton, FuuzeDialog, FuuzeDialogImperativeHandle, InputWrapper} from "@fuuze/shared";
import {useTranslation} from "react-i18next";
import {FuuzeInput} from "../../../Components/Fuuze/FuuzeInput.tsx";
import {FormErrorMessage} from "../../../Components/FormErrorMessage.tsx";
import {FormInputDropzone} from "../../../Components/FormInputDropzone.tsx";
import {useMutation} from "@tanstack/react-query";
import {fetchService} from "../../../utils/query.util.ts";
import {useRef} from "react";
import {updatePodcastChannelInCache} from "../../../Hooks/queries/podcast.queries.hook.ts";
import {Podcast} from "../../../models/podcast.model.ts";
import {updateUserInCache} from "../../../Hooks/queries/user.queries.hook.ts";

const formSchema = yup.object({
  title: yup.string().max(100).required(),
  description: yup.string().max(10000).required(),
  image: yup.mixed().optional(),
});

interface IFormInput extends yup.InferType<typeof formSchema> {
}

export function EditPodcastInfo() {
  const {t} = useTranslation('pages:manageChannel', {keyPrefix: 'channelPreview.editPodcastInfo'});
  const [podcastSelected] = useStore((state) => [state.podcastSelected!]);
  const dialogRef = useRef<FuuzeDialogImperativeHandle>(null);

  const form = useForm<IFormInput>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      title: podcastSelected.title,
      description: podcastSelected.description
    },
  });
  const {isValid, isDirty} = form.formState;

  const updatePodcastInfo = useMutation({
    mutationFn: (data: IFormInput) => {
      const body = new FormData();
      body.append('title', data.title);
      body.append('description', data.description);
      body.append('image', data.image as File);
      return fetchService.post<Podcast>(`/creator/podcast/${podcastSelected.id}`, body);
    },
    onSuccess: (data) => {
      updatePodcastChannelInCache(podcastSelected.id, data);
      updateUserInCache((user) => {
        const indexToUpdate = user.ownedChannels.findIndex(c => c.id === data.id);
        user.ownedChannels[indexToUpdate] = data;
      });
    }
  })

  const onSubmit = async (data: IFormInput) => {
    await updatePodcastInfo.mutateAsync(data);
    dialogRef.current?.close();
    form.reset({title: data.title, description: data.description})
  }

  return (
    <FuuzeDialog
      ref={dialogRef}
      Trigger={<FuuzeButton style={{alignSelf: 'end'}} outline>{t('trigger')}</FuuzeButton>}
      title={t('title')}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FlexColumn>
          <InputWrapper>
            <label className="required" htmlFor="title">
              {t('titleLabel')}
            </label>
            <FuuzeInput id="title" {...form.register('title')} />
            <FormErrorMessage error={form.formState.errors.title?.message}/>
          </InputWrapper>

          <InputWrapper>
            <label className="required" htmlFor="description">
              {t('descriptionLabel')}
            </label>
            <textarea
              style={{resize: 'vertical'}}
              rows={5}
              id="description"
              {...form.register('description')}
            />
            <FormErrorMessage error={form.formState.errors.description?.message}/>
          </InputWrapper>

          <FormProvider {...form}>
            <FormInputDropzone
              dropzoneProps={{
                maxSizeInMb: 5,
                maxFiles: 1,
                accept: {'image/*': ['.png', '.jpg', '.svg']},
              }}
              label={t('pictureLabel')}
              helperText={t('helper')}
              formKey='image'/>
          </FormProvider>

          <FuuzeButton disabled={!isDirty || !isValid}
                       type='submit'
                       loading={updatePodcastInfo.isPending}>
            {t('submit')}
          </FuuzeButton>
        </FlexColumn>
      </form>
    </FuuzeDialog>
  )
}
