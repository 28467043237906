import {FuuzeDropzone} from "./Fuuze/FuuzeDropzone.tsx";
import {FormErrorMessage} from "./FormErrorMessage.tsx";
import {FuuzeColors, InputWrapper} from "@fuuze/shared";
import {DropzoneProps, useDropzone} from "react-dropzone";
import {Controller, useFormContext} from "react-hook-form";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

type FormInputDropzoneProps = {
  dropzoneProps: Omit<DropzoneProps, 'maxSize'> & { maxSizeInMb: number };
  label: string;
  formKey: string;
  helperText?: string;
}

export function FormInputDropzone({dropzoneProps, label, helperText, formKey}: FormInputDropzoneProps) {
  const {t} = useTranslation();

  const maxSize = megabytesToBytes(dropzoneProps.maxSizeInMb);
  const form = useFormContext();
  const dropzone = useDropzone({
    ...dropzoneProps, maxSize, onDropAccepted: (files, event) => {
      form.setValue(formKey, files[0], {shouldDirty: true, shouldValidate: true});
      dropzoneProps.onDropAccepted?.(files, event);
    }
  });

  useEffect(() => {
    if (dropzone.fileRejections.length > 0) {
      form.setError(formKey, {
        type: 'value',
        message: t(`errors.${dropzone.fileRejections[0].errors[0].code}`, {maxSize: prettyPrintBytes(maxSize)})
      });
    }
  }, [dropzone.fileRejections]);

  return (
    <Controller
      control={form.control}
      name={formKey}
      render={({fieldState}) => (
        <InputWrapper>
          <label htmlFor="file">{label}</label>
          <FuuzeDropzone dropzone={dropzone}/>
          <FormErrorMessage error={fieldState.error?.message}/>
          {helperText && <small
            style={{
              marginTop: 8,
              color: FuuzeColors.dark.dark700,
              fontStyle: 'italic',
            }}>
            {helperText}
          </small>
          }
        </InputWrapper>
      )}/>
  )
}

function megabytesToBytes(mb: number): number {
  return mb * 1024 * 1024;
}

function prettyPrintBytes(bytes: number, decimalPlaces = 2) {
  const megabytes = bytes / (1024 * 1024);
  const gigabytes = bytes / (1024 * 1024 * 1024);

  if (gigabytes >= 1) {
    return `${gigabytes.toFixed(decimalPlaces)} GB`;
  } else {
    return `${megabytes.toFixed(decimalPlaces)} MB`;
  }
}