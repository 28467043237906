import {
  InfiniteData,
  MutationOptions,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { produce } from 'immer';
import { PaginateResult } from '../../models/PaginateResult';
import { PodcastEpisode } from '../../models/podcast.model';
import { User } from '../../models/user.model';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { AuthCommon } from '../../Pages/Auth/AuthCommon.tsx';
import { queryClient } from '../../utils/query.util.ts';
import { FuuzeQueryKeys } from './queries.type.ts';
import {PodcastEpisodeDTO, FuuzeError, UserDTO} from "@fuuze/shared";
import {fetchService} from "../../utils/query.util.ts";

export const userQueryKeys = {
  signIn: ['userSignIn', { type: 'user' }] as FuuzeQueryKeys,
  subscribedTo: ['userSubscribedTo', { type: 'podcastEpisode' }] as FuuzeQueryKeys,
};

// ----------------------------------------
// Get user
// ----------------------------------------

const transformRawDataToUser = (data: User) => new User(data);

export function useUser(options?: UseQueryOptions<User, FuuzeError>) {
  return useQuery<User, FuuzeError>({
    ...options,
    staleTime: options?.staleTime || Infinity,
    gcTime: options?.gcTime || Infinity,
    queryKey: userQueryKeys.signIn,
    queryFn: async () => fetchService.fetch<User>('/user/signIn'),
    select: transformRawDataToUser,
  });
}

// ----------------------------------------
// Sign in
// ----------------------------------------

type SignInVariables = { email: string; password: string; provider: 'email' | 'google' };

export function useSignIn(options?: Partial<UseMutationOptions<void, FuuzeError, SignInVariables>>) {
  return useMutation({
    ...options,
    mutationFn: async ({ email, password, provider }) => {
      if (provider === 'email') {
        await signInWithEmailAndPassword(getAuth(), email, password);
      } else {
        await AuthCommon.signInWithGoogle();
      }
    },
  });
}

// ----------------------------------------
// Register user
// ----------------------------------------

type RegisterVariables = { uid: string; email: string };

export function useRegisterUser(options?: Partial<UseMutationOptions<User, FuuzeError, RegisterVariables>>) {
  const queryClient = useQueryClient();

  return useMutation<User, FuuzeError, RegisterVariables>({
    ...options,
    mutationFn: ({ uid, email }) => fetchService.post('/user/signUp', { uid, email }),
    onSuccess: (newUser, variables, context) => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.signIn });
      options?.onSuccess && options.onSuccess(newUser, variables, context);
    },
  });
}

// ----------------------------------------
// Send email verification
// ----------------------------------------

export function useSendEmailVerification(options?: Partial<UseMutationOptions<void, FuuzeError, {resend?: boolean}>>) {
  return useMutation({
    ...options,
    mutationFn: (body) => fetchService.post('/user/sendVerificationEmail', body)
  });
}

// ----------------------------------------
// Verify email
// ----------------------------------------

export function useVerifyEmail(options?: Partial<UseMutationOptions<void, FuuzeError, {code: string}>>) {
  return useMutation<void, FuuzeError, {code: string}>({
    ...options,
    mutationFn: (body) => fetchService.post('/user/verifyEmail', body)
  });
}

// ----------------------------------------
// Get subscribed to items
// ----------------------------------------

const transformSubscribedToData = (data: InfiniteData<PaginateResult<PodcastEpisodeDTO>>) => {
  data.pages.forEach((page) => (page.docs = page.docs.map(PodcastEpisode.transformRawDataToPodcastEpisode)));
  return data as InfiniteData<PaginateResult<PodcastEpisode>>;
}

export function useSubscribedToItems(
  options?: Partial<UndefinedInitialDataInfiniteOptions<PaginateResult<PodcastEpisodeDTO>, FuuzeError, PaginateResult<PodcastEpisode>>>,
) {
  const user = useUser();
  return useInfiniteQuery({
    ...options,
    initialPageParam: options?.initialPageParam || 1,
    queryKey: userQueryKeys.subscribedTo,
    queryFn: async ({ pageParam }) =>
      fetchService.fetch<PaginateResult<PodcastEpisodeDTO>>(
        '/user/subscribedTo?' + new URLSearchParams({ page: pageParam!.toString() }),
      ),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    gcTime: options?.gcTime || 1000 * 60 * 5,
    staleTime: options?.staleTime || 1000 * 60 * 5,
    enabled: user.data && user.data.subscribedChannels.length > 0,
    select: transformSubscribedToData,
  });
}

// ----------------------------------------
// Subscribe to item
// ----------------------------------------

export function useSubscribeToMutation(
  options?: Partial<MutationOptions<User, FuuzeError, { channelId: string }, { previousUser: User }>>,
) {
  const queryClient = useQueryClient();

  return useMutation<User, FuuzeError, { channelId: string }, { previousUser: User }>({
    ...options,
    mutationFn: ({ channelId }) => fetchService.post('/user/subscribeTo', { channelId }),
    onSuccess: (newUser: User, variables, context) => {
      // Update user info in cache
      updateUserInCache(newUser);

      // Just invalidate the queries as updating the cache is too complicated
      queryClient.invalidateQueries({ queryKey: userQueryKeys.subscribedTo });

      options?.onSuccess && options.onSuccess(newUser, variables, context);
    },
    onMutate: async ({ channelId }) => {
      const previousUser = getUserInCache()!;
      updateUserInCache(
        produce(previousUser, (draft) => {
          draft?.subscribedChannels.push(channelId);
        }),
      );
      options?.onMutate && options.onMutate({ channelId });
      return { previousUser };
    },
    onError: (error, _, context) => {
      updateUserInCache(context!.previousUser);
      options?.onError && options.onError(error, _, context);
    },
  });
}

// ----------------------------------------
// Subscribe to item
// ----------------------------------------

export function useUnsubscribeFromMutation(
  options?: Partial<MutationOptions<User, FuuzeError, { channelId: string }, { previousUser: User }>>,
) {
  const queryClient = useQueryClient();

  return useMutation<User, FuuzeError, { channelId: string }, { previousUser: User }>({
    ...options,
    mutationFn: ({ channelId }) => fetchService.post<User>('/user/unsubscribeFrom', { channelId }),
    onSuccess: (newUser, variables, context) => {
      // Update user info in cache
      updateUserInCache(newUser);

      // Just invalidate the queries as updating the cache is too complicated
      queryClient.invalidateQueries({ queryKey: userQueryKeys.subscribedTo });

      options?.onSuccess && options.onSuccess(newUser, variables, context);
    },
    onMutate: async ({ channelId }) => {
      const previousUser = getUserInCache()!;
      updateUserInCache(
        produce(previousUser, (draft) => {
          const index = draft.subscribedChannels.findIndex((id) => id === channelId);
          draft?.subscribedChannels.splice(index, 1);
        }),
      );
      options?.onMutate && options.onMutate({ channelId });
      return { previousUser };
    },
    onError: (error, _, context) => {
      updateUserInCache(context!.previousUser);
      options?.onError && options.onError(error, _, context);
    },
  });
}

// ----------------------------------------
// Update user settings
// ----------------------------------------

export function useUpdateUserSettingsMutation(
  options?: Partial<UseMutationOptions<User, FuuzeError, { country: string }>>,
) {

  return useMutation({
    ...options,
    mutationFn: (data) => fetchService.post<User>('/user/settings', data),
    onSuccess: (newUser, variables, context) => {
      updateUserInCache(newUser);
      options?.onSuccess && options.onSuccess(newUser, variables, context);
    },
  });
}

// ----------------------------------------
// Update pseudo
// ----------------------------------------

export function useUpdatePseudoMutation(options?: Partial<UseMutationOptions<User, FuuzeError, { pseudo: string }>>) {
  return useMutation({
    ...options,
    mutationFn: (data) => fetchService.post<User>('/user/updatePseudo', data),
    onSuccess: (newUser, variables, context) => {
      updateUserInCache(newUser);
      options?.onSuccess && options.onSuccess(newUser, variables, context);
    },
  });
}

// ========== Cache handling ==========

export function updateUserInCache(updates: (user: UserDTO) => void): void;
export function updateUserInCache(updates: Partial<UserDTO>): void;
export function updateUserInCache(updates: Partial<UserDTO> | ((user: UserDTO) => void)): void {
  queryClient.setQueryData(userQueryKeys.signIn, produce(getUserInCache(), (draft) => {
    if (!draft) return;
    if (typeof updates === 'function') updates(draft);
    else Object.assign(draft, updates);
  }));
}

export function getUserInCache() {
  return queryClient.getQueryData<User>(userQueryKeys.signIn);
}
