import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import Stripe from 'stripe';
import { userQueryKeys } from './user.queries.hook.ts';
import { User } from '../../models/user.model.ts';
import { produce } from 'immer';
import { FuuzeQueryKeys } from './queries.type.ts';
import {ChannelDTO, FuuzeError} from "@fuuze/shared";
import {Channel} from "../../models/channel.model.ts";
import {fetchService} from "../../utils/query.util.ts";

const stripeQueryKeys = {
  getCheckoutSession: (id?: string | null) => ['stripe/checkout-session', { type: 'noType' }, id] as FuuzeQueryKeys,
  simulateUpgrade: (priceId?: string, subscriptionId?: string, subscriptionItemId?: string) => [
    'stripe',
    'simulate-upgrade',
    priceId,
    subscriptionId,
    subscriptionItemId,
  ],
};

// ----------------------------------------
// Onboard creator on Stripe
// ----------------------------------------

export function useStripeOnboarding(options?: UseMutationOptions<Stripe.AccountLink>) {
  return useMutation<Stripe.AccountLink>({
    mutationFn: () =>
      fetchService.fetch('/stripe/onboarding?' + new URLSearchParams({ returnUrl: window.location.href })),
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      window.location.replace(data.url);
    },
    ...options,
  });
}

// ----------------------------------------
// Start checkout session
// ----------------------------------------

type StripeStartCheckoutSessionResponse = {
  url: string;
};

type StripeStartCheckoutSessionBody = {
  channelId?: string;
  tierId?: string;
  fromUrl: string;
  mode?: Stripe.Checkout.SessionCreateParams.Mode;
};

export function useStripeStartCheckoutSession(
  options?: Partial<UseMutationOptions<StripeStartCheckoutSessionResponse, FuuzeError, StripeStartCheckoutSessionBody>>,
) {
  return useMutation<StripeStartCheckoutSessionResponse, FuuzeError, StripeStartCheckoutSessionBody>({
    mutationFn: (data) => fetchService.post('/stripe/create-checkout-session', data),
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      window.location.replace(data.url);
    },
    ...options,
  });
}

// ----------------------------------------
// Retrieve checkout session
// ----------------------------------------

const transformStripeCheckoutSession = (data: GetStripeCheckoutSessionResponse) => ({...data, channel: new Channel(data.channel)});

// TODO: transform this into a Channel class
type GetStripeCheckoutSessionResponse = {
  lineItem: Stripe.LineItem;
  channel: ChannelDTO;
};

export function useStripeCheckoutSession(
  id?: string | null,
  options?: Partial<UseQueryOptions<GetStripeCheckoutSessionResponse, FuuzeError, {lineItem: Stripe.LineItem, channel: Channel}>>,
) {
  return useQuery({
    queryKey: stripeQueryKeys.getCheckoutSession(id),
    queryFn: () => fetchService.fetch<GetStripeCheckoutSessionResponse>(`/stripe/checkout-session/${id}`),
    enabled: !!id,
    select: transformStripeCheckoutSession,
    ...options,
  });
}

// ----------------------------------------
// Update subscription
// ----------------------------------------

type UpdateSubscriptionBody = {
  subscriptionId: string;
  cancel?: boolean;
  //channelId?: string;
  //tierId?: string;
  priceId?: string;
  subscriptionItemId?: string;
  paymentMethodId?: string;
};

export function useStripeUpdateSubscription(
  options?: Partial<UseMutationOptions<Stripe.Subscription, FuuzeError, UpdateSubscriptionBody>>,
) {
  const queryClient = useQueryClient();

  return useMutation<Stripe.Subscription, FuuzeError, UpdateSubscriptionBody>({
    mutationFn: (variables) => fetchService.post(`/stripe/subscription/${variables.subscriptionId}`, variables),
    onSuccess: (data, variables, context) => {
      const userInCache = queryClient.getQueryData<User>(userQueryKeys.signIn)!;
      const newUser = produce(userInCache, (draft) => {
        const index = draft.stripeCustomer?.subscriptions?.data.findIndex((s) => s.id === variables.subscriptionId)!;
        draft.stripeCustomer!.subscriptions!.data[index] = data;
      });
      queryClient.setQueryData(userQueryKeys.signIn, newUser);

      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
}

// ----------------------------------------
// Detach payment
// ----------------------------------------

type DetachPaymentBody = {
  paymentMethodId: string;
};

export function useStripeDetachPayment(
  options?: Partial<UseMutationOptions<User['stripeCustomer'], FuuzeError, DetachPaymentBody>>,
) {
  const queryClient = useQueryClient();

  return useMutation<User['stripeCustomer'], FuuzeError, DetachPaymentBody>({
    ...options,
    mutationFn: (variables) => fetchService.post(`/stripe/detach-payment-method`, variables),
    onSuccess: async (data, variables, context) => {
      const userInCache = queryClient.getQueryData<User>(userQueryKeys.signIn)!;
      const newUser = produce(userInCache, (draft) => {
        draft.stripeCustomer = data;
      });
      queryClient.setQueryData(userQueryKeys.signIn, newUser);

      options?.onSuccess?.(data, variables, context);
    },
  });
}

// ----------------------------------------
// Set default payment method
// ----------------------------------------

type SetDefaultPaymentMethodBody = {
  customerId: string;
  paymentMethodId: string;
};

export function useStripeSetDefaultPaymentMethod(
  options?: Partial<UseMutationOptions<void, FuuzeError, SetDefaultPaymentMethodBody>>,
) {
  const queryClient = useQueryClient();

  return useMutation<void, FuuzeError, SetDefaultPaymentMethodBody>({
    ...options,
    mutationFn: (variables) => fetchService.post(`/stripe/set-default-payment-method`, variables),
    onSuccess: (data, variables, context) => {
      const userInCache = queryClient.getQueryData<User>(userQueryKeys.signIn)!;
      const newUser = produce(userInCache, (draft) => {
        draft.stripeCustomer!.invoice_settings.default_payment_method = variables.paymentMethodId;
      });
      queryClient.setQueryData(userQueryKeys.signIn, newUser);

      options?.onSuccess?.(data, variables, context);
    },
  });
}

// ----------------------------------------
// Upgrade subscription
// ----------------------------------------

type UpgradeSubscriptionBody = {
  channelId: string;
  newTierId: string;
  oldTierId: string;
  subscriptionId: string;
  subscriptionItemId: string;
};

export function useStripeUpgradeSubscription(
  options?: Partial<UseMutationOptions<User['stripeCustomer'], FuuzeError, UpgradeSubscriptionBody>>,
) {
  const queryClient = useQueryClient();

  return useMutation<User['stripeCustomer'], FuuzeError, UpgradeSubscriptionBody>({
    ...options,
    mutationFn: (variables) => fetchService.post('/stripe/upgrade-subscription', variables),
    onSuccess: (data, variables, context) => {
      const userInCache = queryClient.getQueryData<User>(userQueryKeys.signIn)!;
      const newUser = produce(userInCache, (draft) => {
        draft.stripeCustomer = data;
      });
      queryClient.setQueryData(userQueryKeys.signIn, newUser);

      options?.onSuccess?.(data, variables, context);
    },
  });
}

// // ----------------------------------------
// // Simulate Upgrade subscription
// // ----------------------------------------
//
// export function useSimulateStripeUpgradeSubscription(
//   priceId?: string,
//   subscriptionId?: string,
//   subscriptionItemId?: string,
//   options?: UseQueryOptions<Stripe.UpcomingInvoice, FuuzeError>,
// ) {
//   return useQuery<Stripe.UpcomingInvoice, FuuzeError>({
//     ...options,
//     queryKey: stripeQueryKeys.simulateUpgrade(priceId, subscriptionId, subscriptionItemId),
//     queryFn: () =>
//       fetchService.post('/stripe/upgrade-subscription/simulate', {
//         priceId,
//         subscriptionId,
//         subscriptionItemId,
//       }),
//     enabled: options && 'enabled' in options ? options.enabled : !!priceId && !!subscriptionId && !!subscriptionItemId,
//     gcTime: 60 * 1000 * 5,
//     staleTime: 60 * 1000 * 5,
//   });
// }

// ----------------------------------------
// Upgrade subscription
// ----------------------------------------

type DowngradeSubscriptionBody = {
  subscriptionId: string;
  priceId: string;
};

export function useStripeDowngradeSubscription(
  options?: Partial<UseMutationOptions<User['stripeCustomer'], FuuzeError, DowngradeSubscriptionBody>>,
) {
  const queryClient = useQueryClient();

  return useMutation<User['stripeCustomer'], FuuzeError, DowngradeSubscriptionBody>({
    ...options,
    mutationFn: (variables) => fetchService.post('/stripe/downgrade-subscription', variables),
    onSuccess: (data, variables, context) => {
      const userInCache = queryClient.getQueryData<User>(userQueryKeys.signIn)!;
      const newUser = produce(userInCache, (draft) => {
        draft.stripeCustomer = data;
      });
      queryClient.setQueryData(userQueryKeys.signIn, newUser);

      options?.onSuccess?.(data, variables, context);
    },
  });
}
