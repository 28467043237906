import {QueryClient} from '@tanstack/react-query';
import {FuuzeQueryKeys} from "../Hooks/queries/queries.type.ts";
import {userQueryKeys} from "../Hooks/queries/user.queries.hook.ts";
import {User} from "../models/user.model.ts";
import {Podcast} from "../models/podcast.model.ts";
import {useStore} from "../store/store.ts";
import { FetchService } from '@fuuze/shared';

export const fetchService = new FetchService(import.meta.env.VITE_BACKEND_URL);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
});

// Refresh the podcast selected when the user ownedChannels are updated
// So in order to trigger a refresh of the podcast selected, update the user data
let previousUser: User | undefined;

queryClient.getQueryCache().subscribe(event => {
  if (event.type === 'updated' && (event.query.queryKey as FuuzeQueryKeys)[0] === userQueryKeys.signIn[0] && event.query.state.data) {
    const currentPodcastSelected = useStore.getState().podcastSelected;
    const user = new User(event.query.state.data);
    const ownedChannels = user.ownedChannels as Podcast[];
    if (ownedChannels.length === 0) return;
    if (!currentPodcastSelected) useStore.setState({podcastSelected: ownedChannels[0]});
    else {
      if (previousUser?.ownedChannels.length === ownedChannels.length) {
        const updatedPodcast = ownedChannels.find((c) => c.id === currentPodcastSelected.id);
        if (updatedPodcast) {
          useStore.setState({podcastSelected: updatedPodcast});
        }
      } else {
        useStore.setState({podcastSelected: ownedChannels.at(-1)});
      }
    }
    previousUser = user;
  }
});