import * as yup from 'yup';

export const yupLocale: yup.LocaleObject = {
  mixed: {
    default: {
      key: 'validations.invalid',
    },
    required: {
      key: 'validations.required',
    },
    notType: ({ type }) => ({
      key: `validations.invalidType.${type}`
    }),
  },
  string: {
    email: {
      key: 'validations.email',
    },
    min: ({min}) => ({
      key: 'validations.stringMin',
      values: {min},
    }),
    max: ({max}) => ({
      key: 'validations.stringMax',
      values: {max},
    }),
    url: {
      key: 'validations.url',
    },
  },
  number: {
    min: ({min}) => ({
      key: 'validations.numberMin',
      values: {min},
    }),
    max: ({max}) => ({
      key: 'validations.numberMax',
      values: {max},
    }),
  },
  boolean: {},
};
