import { useTranslation } from 'react-i18next';

export interface ValidationError {
  /** key into translation file */
  key: string;

  /** token values */
  values?: { [key: string]: any };
}

export interface ErrorMessageProps {
  error?: ValidationError | string;
  ns?: `pages:${string}` | `components:${string}`;
}

export const FormErrorMessage = ({ error, ns }: ErrorMessageProps) => {
  const { t } = useTranslation(ns);

  if (error === undefined) {
    return null;
  } else if (typeof error === 'string') {
    return <small className="error">{t(error)}</small>;
  } else {
    const { key, values } = error;
    return <small className="error">{t(key, values)}</small>;
  }
};
