import {
  InfiniteData,
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import {Podcast, PodcastEpisode} from '../../models/podcast.model';
import {PaginateResult} from '../../models/PaginateResult';
import {produce} from 'immer';
import {queryClient} from '../../utils/query.util.ts';
import {FuuzeQueryKeys} from './queries.type.ts';
import {FuuzeError, PodcastDTO, PodcastEpisodeDTO, UserDTO} from "@fuuze/shared";
import {fetchService} from "../../utils/query.util.ts";
import {userQueryKeys} from "./user.queries.hook.ts";

export const podcastQueryKeys = {
  podcastChannelBySlug: (slug?: string) => ['podcastBySlug', {type: 'podcastChannel'}, slug] as FuuzeQueryKeys,
  podcastChannelById: (channelId?: string) => ['podcastById', {type: 'podcastChannel'}, channelId] as FuuzeQueryKeys,
  podcastEpisodes: (channelId?: string) => ['podcastEpisodes', {type: 'podcastEpisode'}, channelId] as FuuzeQueryKeys,
  podcastEpisode: (slug?: string) => ['podcastEpisode', {type: 'podcastEpisode'}, slug] as FuuzeQueryKeys,
  podcastFeedInfo: (feedUrl?: string) => ['podcastFeedInfo', {type: 'podcastChannel'}, feedUrl] as FuuzeQueryKeys,
};

// ----------------------------------------
// Get podcast channel
// ----------------------------------------

const transformPodcastChannel = (data: PodcastChannelResponse) => ({
  ...data,
  channel: Podcast.transformRawDataToPodcast(data.channel)
})

type PodcastChannelQueryParams = {
  slug?: string;
  feedUrl?: string;
  itunesTrackId?: number;
};

type PodcastChannelResponse = {
  channel: PodcastDTO;
  inCreation: boolean;
};

/**
 * Get a podcast channel by feed url
 */
export function usePodcastChannel(
  {slug, feedUrl, itunesTrackId}: PodcastChannelQueryParams,
  options?: Partial<UseQueryOptions<PodcastChannelResponse, FuuzeError, { inCreation: boolean; channel: Podcast }>>,
) {
  return useQuery({
    ...options,
    staleTime: options?.staleTime ?? Infinity,
    gcTime: options?.gcTime ?? Infinity,
    queryKey: podcastQueryKeys.podcastChannelBySlug(slug),
    enabled: options && 'enabled' in options ? options.enabled : !!slug,
    queryFn: async () =>
      fetchService.fetch<PodcastChannelResponse>(
        '/podcast?' +
        new URLSearchParams({
          ...(feedUrl && {feedUrl}),
          ...(slug && {slug}),
          ...(itunesTrackId && {itunesTrackId: itunesTrackId.toString()}),
        }),
      ),
    select: transformPodcastChannel
  });
}

// ----------------------------------------
// Get podcast channel by id
// ----------------------------------------

type PodcastChannelByIdQueryParams = {
  channelId?: string;
};

type PodcastChannelByIdResponse = {
  channel: PodcastDTO;
  inCreation: boolean;
};

const transformPodcastByChannelId = (data: PodcastChannelByIdResponse) => ({
  ...data,
  channel: Podcast.transformRawDataToPodcast(data.channel)
});

/**
 * Get a podcast channel by id
 */
export function usePodcastChannelById(
  {channelId}: PodcastChannelByIdQueryParams,
  options?: Partial<UseQueryOptions<PodcastChannelByIdResponse, FuuzeError, {
    inCreation: boolean;
    channel: Podcast
  }>>,
) {
  return useQuery({
    ...options,
    staleTime: options?.staleTime ?? Infinity,
    gcTime: options?.gcTime ?? Infinity,
    queryKey: podcastQueryKeys.podcastChannelById(channelId),
    enabled: options && 'enabled' in options ? options.enabled : !!channelId,
    queryFn: async () => fetchService.fetch<PodcastChannelByIdResponse>('/podcast?' + new URLSearchParams({channelId: channelId!})),
    select: transformPodcastByChannelId
  });
}

// ----------------------------------------
// Get podcast episodes
// ----------------------------------------

const transformPodcastEpisodes = (data: InfiniteData<PaginateResult<PodcastEpisodeDTO>>): InfiniteData<PaginateResult<PodcastEpisode>> => {
  return produce(data, draft => {
    draft.pages.forEach(page => {
      page.docs = page.docs.map(doc => new PodcastEpisode(doc));
    });
  }) as InfiniteData<PaginateResult<PodcastEpisode>>;
}

/**
 * Get a list of podcast episodes by channel id
 */
export function usePodcastEpisodes(
  channelId?: string,
  options?: Partial<UndefinedInitialDataInfiniteOptions<PaginateResult<PodcastEpisodeDTO>, FuuzeError, PaginateResult<PodcastEpisode>>>,
) {
  return useInfiniteQuery({
    ...options,
    queryKey: podcastQueryKeys.podcastEpisodes(channelId),
    initialPageParam: options?.initialPageParam ?? 1,
    queryFn: async ({pageParam}) => {
      return await fetchService.fetch(
        '/podcast/episodes?' +
        new URLSearchParams({
          channelId: channelId!,
          page: pageParam!.toString(),
        }),
      );
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: options && 'enabled' in options ? options.enabled : !!channelId,
    select: transformPodcastEpisodes
  });
}

// ----------------------------------------
// Get podcast episode
// ----------------------------------------

const transformPodcastEpisode = (data: PodcastEpisodeDTO) => PodcastEpisode.transformRawDataToPodcastEpisode(data);

/**
 * Get a podcast episode by slug.
 */
export function usePodcastEpisode(slug?: string, options?: Partial<UseQueryOptions<PodcastEpisodeDTO, FuuzeError, PodcastEpisode>>) {
  return useQuery({
    ...options,
    staleTime: options?.staleTime ?? Infinity,
    gcTime: options?.gcTime ?? Infinity,
    queryKey: podcastQueryKeys.podcastEpisode(slug),
    queryFn: async () => await fetchService.fetch<PodcastEpisodeDTO>(`/podcast/episode?` + new URLSearchParams({slug: slug!})),
    enabled: options && 'enabled' in options ? options.enabled : !!slug,
    select: transformPodcastEpisode,
    initialData: () => {
      const queriesData = queryClient.getQueriesData<ReturnType<typeof usePodcastEpisodes>['data']>({
        predicate: (query) => query.queryKey[0] === podcastQueryKeys.podcastEpisodes()[0],
      });
      for (const query of queriesData) {
        const pages = query[1]?.pages;
        if (pages) {
          for (const page of pages) {
            const episode = page.docs.find((e) => e.slug === slug);
            if (episode) return new PodcastEpisode(episode);
          }
        }
      }
      return undefined;
    },
  });
}

// ----------------------------------------
// Get podcast from feed url
// ----------------------------------------

export function useChannelDetailsFromFeedUrl(feedUrl?: string, options?: Partial<UseQueryOptions<PodcastDTO, FuuzeError, Podcast>>) {
  return useQuery({
    queryKey: podcastQueryKeys.podcastFeedInfo(feedUrl),
    queryFn: () => fetchService.fetch<PodcastDTO>('/podcast/feedInfo?' + new URLSearchParams({feedUrl: feedUrl!})),
    select: Podcast.transformRawDataToPodcast,
    ...options,
  });
}

// ========== Cache handling ==========

export function getPodcastEpisodeFromCache(queryClient: QueryClient, channelId: string) {
  return queryClient.getQueryData<ReturnType<typeof usePodcastEpisodes>['data']>(
    podcastQueryKeys.podcastEpisodes(channelId),
  );
}

export function setPodcastEpisodesInCache(
  queryClient: QueryClient,
  channelId: string,
  episodes: ReturnType<typeof usePodcastEpisodes>['data'],
) {
  queryClient.setQueryData(podcastQueryKeys.podcastEpisodes(channelId), episodes);
}

export function updatePodcastChannelInCache(channelId: string, updates: Partial<PodcastDTO>) {
  queryClient
    .getQueryCache()
    .findAll({predicate: (query) => (query.queryKey as FuuzeQueryKeys)[1]?.type === 'podcastChannel' && (query.state.data as PodcastDTO).id === channelId})
    .forEach((query) => {
      query.setData(
        produce(query.state.data as PodcastDTO, (draft) => {
          Object.assign(draft, updates);
        }),
      );
    });

  // Update the podcast if it's one of the user's owned podcasts
  const user = queryClient.getQueryData<UserDTO>(userQueryKeys.signIn);
  const userOwnedChannelMatchingIndex = user?.ownedChannels.findIndex(channel => typeof channel !== 'string' && channel.id === channelId) || -1;
  if (userOwnedChannelMatchingIndex !== -1) {
    queryClient.setQueryData(userQueryKeys.signIn, produce(user, (draft) => {
      if (!draft) return;
      draft.ownedChannels[userOwnedChannelMatchingIndex] = {...(draft.ownedChannels[userOwnedChannelMatchingIndex] as PodcastDTO), ...updates};
    }));
  }
}

export function updatePodcastEpisodeInCache(
  filter: (episode: PodcastEpisodeDTO) => boolean,
  updates: Partial<PodcastEpisode>,
) {
  queryClient
    .getQueryCache()
    .findAll({
      predicate: (query) => (query.queryKey as FuuzeQueryKeys)[1]?.type === 'podcastEpisode' && !!query.state.data,
    })
    .forEach((query) => {
      const data = query.state.data as any;
      if (data['pages'] && data['pageParams']) {
        // Results are paginated
        query.setData(
          produce(data as InfiniteData<PaginateResult<PodcastEpisodeDTO>>, (draft) => {
            draft.pages.forEach((page) => {
              page.docs.forEach((episode) => {
                if (filter(episode)) {
                  Object.assign(episode, updates);
                }
              });
            });
          }),
        );
      } else {
        // Results are not paginated
        query.setData(
          produce(data as PodcastEpisodeDTO, (draft) => {
            if (filter(draft)) {
              Object.assign(draft, updates);
            }
          }),
        );
      }
    });
}
