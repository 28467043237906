import {ChannelDTO} from "@fuuze/shared";
import {immerable} from "immer";
import {Tier} from "./tier.model.ts";

export class Channel implements ChannelDTO {
  [immerable] = true

  id: string;
  categories?: string[] | undefined;
  copyright?: string | undefined;
  description: string;
  generator?: string | undefined;
  image: string;
  image30?: string | undefined;
  image60?: string | undefined;
  image100?: string | undefined;
  image600?: string | undefined;
  language?: string | undefined;
  lastBuildDate?: Date | undefined;
  link?: string | undefined;
  pubDate?: Date | undefined;
  slug: string;
  tiers: Tier[];
  title: string;

  constructor(channel: ChannelDTO) {
    this.id = channel.id;
    this.categories = channel.categories;
    this.description = channel.description;
    this.generator = channel.generator;
    this.image = channel.image;
    this.language = channel.language;
    this.lastBuildDate = channel.lastBuildDate;
    this.link = channel.link;
    this.pubDate = channel.pubDate;
    this.slug = channel.slug;
    this.tiers = channel.tiers.map(tier => new Tier(tier));
    this.title = channel.title;
  }
}