import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../Hooks/queries/user.queries.hook.ts';

export function IsUserCreatorRouteGuard(props: PropsWithChildren) {
  const user = useUser();

  if (!user.data?.isCreator) {
    return <Navigate to="/becomeCreator" replace={true} />;
  }

  return props.children;
}
