import styled from "styled-components";
import {DropzoneState} from "react-dropzone";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";

type FuuzeDropzoneProps = {
  dropzone: DropzoneState;
};

export function FuuzeDropzone({dropzone}: FuuzeDropzoneProps) {

  // useSuspense: false otherwise it will create a rerender and when included in a drawer (for mobile only) it will close the drawer
  const {t} = useTranslation('components:fuuzeDropzone', {useSuspense: false});

  const classNames = useMemo(() => {
    let classes = '';
    if (dropzone.isFocused) classes += ' focused';
    if (dropzone.isDragAccept) classes += ' accepted';
    if (dropzone.isDragReject) classes += ' rejected';
    return classes;
  }, [dropzone]);

  return (
    <DropSection
      {...dropzone.getRootProps({
        className: classNames,
      })}>
      <input id="file" {...dropzone.getInputProps()} />
      <p>{dropzone.acceptedFiles[0]?.name || dropzone.fileRejections[0]?.file?.name || t('helper')}</p>
    </DropSection>
  )
}

const DropSection = styled.section`
  border: 2px dashed ${(props) => props.theme.colors.dark.dark500};
  border-radius: 4px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.focused {
    outline: none;
    border-color: ${(props) => props.theme.colors.orange.orange80};
  }

  &.accepted {
    border-color: ${(props) => props.theme.colors.green};
  }

  &.rejected {
    border-color: ${(props) => props.theme.colors.red};
  }
`;