import { useQuery } from '@tanstack/react-query';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithRedirect } from 'firebase/auth';
import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mediaQuery, FuuzeError } from "@fuuze/shared";
import { isBrowserMobile } from '../../utils/document.util';
import {fetchService} from "../../utils/query.util.ts";

export namespace AuthCommon {
  export const Form = styled.form`
    min-height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    margin: 8px;
    background-color: rgba(142, 142, 142, 0.1);
    border: 1px solid rgb(96, 96, 96);
    border-radius: 24px;
    width: 458px;

    @media ${mediaQuery.tablet} {
      padding: 32px;
      margin: 16px;
    }
  `;

  export const BackgroundGradient = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //background: radial-gradient(6726.99% 6970.23% at -46.81% 87.94%, rgba(255, 149, 52, 0.2) 0%, rgba(0, 0, 0, 0.2) 95.83%);
    background: radial-gradient(circle at center, rgba(255, 149, 52, 0.2), transparent);
    filter: blur(150px);
    width: 100%;
    height: 100%;
    //max-width: 688px;
    //max-height: 688px;
    z-index: -1;

    @media ${mediaQuery.tablet} {
      //max-width: 846px;
      //max-height: 846px;
    }
  `;

  export function Divider() {
    const { t } = useTranslation();
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <div style={{ backgroundColor: 'white', height: 1, flexGrow: 2 }}></div>
        <p style={{ flexGrow: 1, textAlign: 'center' }}>{t('common.or')}</p>
        <div style={{ backgroundColor: 'white', height: 1, flexGrow: 2 }}></div>
      </div>
    );
  }

  export function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    if (isBrowserMobile()) {
      return signInWithRedirect(getAuth(), provider);
    } else {
      return signInWithPopup(getAuth(), provider);
    }
  }

  export function useCheckPseudoAvailability() {
    const [debouncedPseudo, setDebouncedPseudo] = useState('');

    const debouncePseudo = useCallback(
      debounce((value) => setDebouncedPseudo(value), 300),
      [],
    );

    const checkPseudoAvailability = useQuery<{ isAvailable: boolean } | undefined, FuuzeError>({
      queryKey: ['/user/checkPseudoAvailability', debouncedPseudo],
      queryFn: async () =>
        fetchService.post<{
          isAvailable: boolean;
        }>('/user/checkPseudoAvailability', { pseudo: debouncedPseudo }),
      enabled: !!debouncedPseudo && debouncedPseudo.length > 2,
    });

    return [debouncePseudo, checkPseudoAvailability] as const;
  }
}
