import {ChannelDTO, FlexColumn, FlexRow, FuuzeImage, mediaQuery} from "@fuuze/shared";
import styled from 'styled-components';
import {ReadMore} from "../../../Components/ReadMore.tsx";
import {EditPodcastInfo} from "./EditPodcastInfo.tsx";

type ChannelPreviewProps = {
  channel: ChannelDTO;
};

export function ChannelPreview({channel}: ChannelPreviewProps) {

  return (
    <>
      <FlexRow>
        <FlexColumn>
          <Cover src={`${channel.image600}?${Date.now()}`} fallbackSrc={`${channel.image}?${Date.now()}`}/>
        </FlexColumn>

        <FlexColumn style={{marginLeft: 16, flex: 1}}>
          <h1>{channel.title}</h1>
          <div style={{flex: 1}}>
            <ReadMore lines={5} text={channel.description} isHtml/>
          </div>
        </FlexColumn>
      </FlexRow>

      <EditPodcastInfo/>
    </>
  );
}

const Cover = styled(FuuzeImage)`
  border-radius: 16px;
  height: 150px;

  @media ${mediaQuery.tablet} {
    height: 200px;
  }
`