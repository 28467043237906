import {RouterProvider} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import './i18n';
import {FuuzeTheme, GlobalStyle} from "@fuuze/shared";
import {router} from './routes';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {setUseWhatChange} from "@simbathesailor/use-what-changed";

setUseWhatChange(import.meta.env.DEV);

export default function App() {
  // usePerformActionBeforeNavigatingAway();

  return (
    <ThemeProvider theme={FuuzeTheme}>
      <GlobalStyle/>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left"/>
      <RouterProvider router={router}/>
    </ThemeProvider>
  );
}