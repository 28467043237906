import {create} from 'zustand';
import {persist, PersistOptions, StorageValue, subscribeWithSelector} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';
import superjson from 'superjson';
import {Howl} from 'howler';
import {updatePodcastEpisodeInCache} from '../Hooks/queries/podcast.queries.hook.ts';
import {fetchService} from '../utils/query.util.ts';
import {ItemUser} from '../models/ItemUser.ts';
import {PodcastEpisode} from "../models/podcast.model.ts";

// ----------------------------------------
// Types
// ----------------------------------------

type TrackInfo = {
  duration: number;
  position: number;
};

type PlayerStoreState = {
  _howl: Howl | undefined;
  duration: number;
  isLoading: boolean;
  isPlaying: boolean;
  playerCollapsedHeight: number;
  playerPosition: number;
  podcastPlaying: PodcastEpisode | undefined;
  trackInfo: Map<string, TrackInfo>; // Map<itemId, TrackInfo>
};

type PlayerStoreActions = {
  backward: () => void;
  cancelListener: () => void;
  forward: () => void;
  loadAudio: (item: PodcastEpisode, { play }: { play: boolean }) => Promise<Howl>;
  playPause: () => Promise<void>;
  reset: () => void;
  seek: (value: number) => Promise<void>;
  setPlayerCollapsedHeight: (value: number) => void;
  setPosition: (value: number) => void;
  startListener: () => void;
};

type PlayerStore = PlayerStoreState & PlayerStoreActions;
type LocalStorageState = Pick<PlayerStoreState, 'podcastPlaying'>;

// ----------------------------------------
// Store
// ----------------------------------------

const initialPlayerSliceState: PlayerStoreState = {
  _howl: undefined,
  duration: 0,
  isLoading: false,
  isPlaying: false,
  playerCollapsedHeight: 0,
  playerPosition: 0,
  podcastPlaying: undefined,
  trackInfo: new Map(),
};

const persistConfig: PersistOptions<PlayerStore, LocalStorageState> = {
  name: 'fuuze-player-store',
  storage: {
    getItem: (name) => {
      const item = localStorage.getItem(name);
      if (!item) return null;
      return superjson.parse<StorageValue<LocalStorageState>>(item);
    },
    setItem: (name, value) => {
      localStorage.setItem(name, superjson.stringify(value));
    },
    removeItem: (name) => {
      localStorage.removeItem(name);
    },
  },
  partialize: (state) => ({
    podcastPlaying: state.podcastPlaying,
  }),
  merge: (persisted, initial) => {
    if ((persisted as LocalStorageState).podcastPlaying && !(persisted as LocalStorageState).podcastPlaying?.itemUser) {
      console.error('INVALID PERSISTED STATE, ITEMUSER IS MISSING', persisted);
    }
    return {
      ...initial,
      ...(persisted as LocalStorageState),
      playerPosition: (persisted as LocalStorageState).podcastPlaying?.itemUser?.position || 0,
      duration: (persisted as LocalStorageState).podcastPlaying?.duration || 0,
    };
  },
};

let playerListenerInterval: NodeJS.Timeout | undefined;
let sendTrackInfoInterval: NodeJS.Timeout | undefined;

document.addEventListener('visibilitychange', function () {
  if (document.visibilityState === 'hidden') {
    sendTrackInfoToServer(usePlayerStore.getState().trackInfo, true);
  }
});

export const usePlayerStore = create<PlayerStore>()(
  subscribeWithSelector(
    persist(
      immer((set, get) => {
        playerListenerInterval = startPlayerListenerInterval();
        sendTrackInfoInterval = startIntervalSendingTrackInfoEvery5Seconds();

        if (import.meta.hot) {
          import.meta.hot.on('vite:beforeUpdate', () => {
            clearInterval(playerListenerInterval);
            clearInterval(sendTrackInfoInterval);
          });
        }

        return {
          ...initialPlayerSliceState,
          playPause: async () => {
            let howl = get()._howl;
            const podcastPlaying = get().podcastPlaying;

            if (!howl && podcastPlaying) {
              await get().loadAudio(podcastPlaying, { play: true });
            }

            if (howl?.playing()) {
              howl.pause();
              set({ isPlaying: false });
            } else {
              howl?.play();
              set({ isPlaying: true });
            }

            await sendTrackInfoToServer(get().trackInfo);
          },
          loadAudio: async (item, { play }) => {
            const howl = await load(item, get()._howl);
            set({
              _howl: howl,
              podcastPlaying: item,
              isLoading: true,
            });

            if (play) {
              get().playPause();
            }

            return howl;
          },
          reset: () => {
            set(initialPlayerSliceState);
          },
          seek: async (value: number) => {
            let _howl = get()._howl;
            const podcastPlaying = get().podcastPlaying;
            if (!_howl && podcastPlaying) {
              _howl = await get().loadAudio(podcastPlaying, { play: false });
            }
            _howl?.seek(value);
            set({ playerPosition: value });
          },
          setPosition: (value: number) => {
            set({ playerPosition: value });
          },
          cancelListener: () => clearInterval(playerListenerInterval),
          startListener: () => (playerListenerInterval = startPlayerListenerInterval()),
          forward: () => {
            const { playerPosition, podcastPlaying, seek, duration } = get();
            if (!podcastPlaying) return;
            if (playerPosition + 15 < duration) {
              seek(playerPosition + 15);
            } else {
              seek(duration);
            }
          },
          backward: () => {
            const { playerPosition, seek } = get();
            if (playerPosition - 15 > 0) {
              seek(playerPosition - 15);
            } else {
              seek(0);
            }
          },
          setPlayerCollapsedHeight: (value: number) => set({ playerCollapsedHeight: value }),
        };
      }),
      persistConfig,
    ),
  ),
);

// ----------------------------------------
// Functions
// ----------------------------------------

function startIntervalSendingTrackInfoEvery5Seconds() {
  return setInterval(async () => {
    await sendTrackInfoToServer(usePlayerStore.getState().trackInfo);
  }, 5000);
}

function startPlayerListenerInterval() {
  return setInterval(() => {
    const { _howl, podcastPlaying, duration } = usePlayerStore.getState();
    if (_howl?.playing() && podcastPlaying) {
      usePlayerStore.setState((state) => {
        state.playerPosition = _howl.seek();
        state.trackInfo.set(podcastPlaying.id, {
          duration,
          position: state.playerPosition,
        });
      });
    }
  }, 500);
}

async function load(item: PodcastEpisode, howl?: Howl): Promise<Howl> {
  let url = item.enclosureUrl;
  if (!url) {
    const response = await fetchService.fetch<{
      url: string;
      expiresInMs: number;
    }>(`/podcast/${item.channel.id}/episode/${item.id}/streamUrl`);
    url = response.url;
  }

  howl?.unload();

  const newHowl = new Howl({
    src: url,
    html5: true,
    autoplay: false,
    preload: true,
    format: 'mp3',
  });
  if (item.itemUser) {
    newHowl.seek(item.itemUser.position);
  }
  newHowl.on('end', () => {
    usePlayerStore.setState({
      isPlaying: false,
    })
  });
  newHowl.on('load', () => {
    usePlayerStore.setState({
      isLoading: false,
      duration: newHowl.duration(),
      playerPosition: item.itemUser?.position || 0,
    });
  });
  newHowl.on('play', () => {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: item.title,
      artist: item.author,
      artwork: [{ src: item.image, sizes: '300x300', type: 'image/png' }],
    });

    navigator.mediaSession.setActionHandler('play', () => usePlayerStore.getState().playPause());
    navigator.mediaSession.setActionHandler('pause', () => usePlayerStore.getState().playPause());
    navigator.mediaSession.setActionHandler('seekbackward', () => usePlayerStore.getState().backward());
    navigator.mediaSession.setActionHandler('seekforward', () => usePlayerStore.getState().forward());
  });

  return newHowl;
}

async function sendTrackInfoToServer(trackInfo: PlayerStoreState['trackInfo'], keepalive = false) {
  if (trackInfo.size === 0) return;

  const trackInfosList = Array.from(trackInfo.entries()).map(([itemId, trackInfo]) => ({
    itemId,
    position: trackInfo.position,
    duration: trackInfo.duration,
  }));

  try {
    const response = await fetchService.post<Record<string, ItemUser>>('/itemUser/track', trackInfosList, {
      keepalive,
    });
    const podcastPlaying = usePlayerStore.getState().podcastPlaying!;
    Object.entries(response).forEach(([itemId, itemUser]) => {
      updatePodcastEpisodeInCache((episode) => episode.id === itemId, { itemUser });
      if (itemId === podcastPlaying.id) {
        usePlayerStore.setState((state) => {
          state.podcastPlaying!.itemUser = itemUser;
        });
      }
    });

    usePlayerStore.setState((state) => {
      state.trackInfo.clear();
    });
  } catch (e) {
    console.error(e);
  }
}
