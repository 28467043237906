import {immerable} from "immer";
import i18n from "../i18n.ts";
import {TierDTO} from "@fuuze/dto";

export class Tier implements TierDTO {
  [immerable] = true

  id: string;
  amount: number;
  benefits: string[];
  description: string;
  isVisible: boolean;
  stripePrice: {
    currency: string;
    id: string;
    metadata: { [x: string]: string; };
    product: any;
    unit_amount: number | null;
  };
  subscribers: any[];
  title: string;

  constructor(data: TierDTO) {
    this.id = data.id;
    this.amount = data.amount;
    this.benefits = data.benefits;
    this.description = data.description;
    this.isVisible = data.isVisible;
    this.stripePrice = data.stripePrice;
    this.subscribers = data.subscribers;
    this.title = data.title;
  }

  formatPriceWithCurrency() {
    return Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: this.stripePrice.currency,
    }).format(this.amount);
  }
}
