import { StrictMode } from 'react';

import { createRoot, Root } from 'react-dom/client';

// import './wdyr';
import { yupLocale } from './utils/yupLocales.util';
import { setLocale } from 'yup';
import ReactModal from 'react-modal';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from 'react-loading-skeleton';
//
// import 'swiper/css';
// import 'swiper/css/pagination';
import 'react-toastify/dist/ReactToastify.min.css';
import { QueryClientProvider } from '@tanstack/react-query';
import App from './App.tsx';
import { queryClient } from './utils/query.util.ts';
import {FuuzeColors} from "@fuuze/shared";
import {FirebaseService} from "./services/firebase.service.ts";

let root: Root | null = null;

const loadApp = async () => {
  if (!root) {
    root = createRoot(document.getElementById('root') as HTMLElement);
  }
  root.render(
    <StrictMode>
      <SkeletonTheme baseColor={FuuzeColors.dark.dark200} highlightColor={FuuzeColors.dark.dark300}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </SkeletonTheme>
    </StrictMode>,
  );
};

setLocale(yupLocale);
FirebaseService.initializeApp();
await loadApp();
ReactModal.setAppElement('#root');
